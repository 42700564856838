<template>
  <div class="controllerYongyao">
    <div class="controller-modules-header left">药物使用</div>
    <div class="selectForm">
      <div class="item left item1">
        <h4>药物</h4>
        <a-select v-model:value="selectValue" show-search :filter-option="filterOption" placeholder="请选择" @change="selectChange">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item.drugId" :label="item.drugName" v-for="(item,index) in drug1Data" :key="index">{{item.drugName}}</a-select-option>
        </a-select>
        <button  v-show="missionType != 'assess'" class="controller-btn active" @click="showMedicineInfo">
          {{medicine?'隐藏':'显示'}}药品信息
        </button>
      </div>
      <div class="item left" v-show="selectValue && selectData.bolus && selectData.bolusOptions.length != 0">
        <h4>推注</h4>
        <a-select v-model:value="selectValue1" show-search :filter-option="filterOption" placeholder="请选择">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" :label="item" v-for="(item,index) in selectData.bolusOptions" :key="index">{{item}}</a-select-option>
        </a-select>
        {{selectData.bolus}}
      </div>
      <div class="item left" v-show="selectValue && selectData.infusion && selectData.infusionOptions.length != 0">
        <h4>输注</h4>
        <a-select v-model:value="selectValue2" show-search :filter-option="filterOption" placeholder="请选择">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option :value="item" :label="item" v-for="(item,index) in selectData.infusionOptions" :key="index">{{item}}</a-select-option>
        </a-select>
        {{selectData.infusion}}
      </div>
    </div>
    <button class="controller-btn no-margin-top" :class="submitController?'active':''"  @click="submit">确认</button>
    <block v-show="medicine">
      <div class="controller-modules-header left">药品信息</div>
      <div class="controller-modules-log medicine">
        <div class="item" v-html="selectData.drugDesc"></div>
      </div>
    </block>
    <div class="controller-modules-header left">操作记录</div>
    <div class="controller-modules-log">
       <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      selectValue: undefined,
      selectValue1:undefined,
      selectValue2: undefined,
      medicine: false,
      selectData:{},
      formateSeconds,
      actionLog:[],
    };
  },
  created(){
    this.getActionLog();
    console.log('created')
  },
  mounted(){
    console.log('mounted')
  },
  onMounted(){
    console.log('onMounted')
  },
  methods: {
     ...mapActions({
      submitStep: "exma/submitStep",
    }),
    //数据初始化
    initData(){
      this.selectValue = undefined;
      this.selectValue1 = undefined;
      this.selectValue2 = undefined;
      this.medicine = false;
      this.selectData = {};
    },
    //显示药品信息
    showMedicineInfo() {
      if(!this.selectValue){
        this.$message.error('请选择药物')
        return;
      };
      this.medicine = !this.medicine;
    },
    selectChange(value) {
      this.selectValue1 = undefined;
      this.selectValue2 = undefined;
      let _d = this.drug1Data.find((e)=>e.drugId == value);
      this.selectData = _d || {};
    },
    submit() {
      if(!this.submitController)return;
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component:'drug',
        drugId:this.selectValue,
        infusion:this.selectValue2,
        bolus:this.selectValue1
        
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
        this.initData();
      });
    },
    handlerRadio(model, type) {
      this[model] = type || "";
    },
    async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'drug',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
  computed: {
    ...mapGetters(["getHandleTimeAt", "memberCaseId","drug1Data","missionType"]),
    submitController() {
      if(!this.selectValue)return false;
      if(this.selectData.bolus){
        if(!this.selectValue1)return false;
      }
      if(this.selectData.infusion){
        if(!this.selectValue2)return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>